import React, { useState } from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
  PrimaryModal,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, headerbg, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          center
          justifyBetween
        >
          <Header />
          <CFView
            column
            center
            ph="3%"
            w="100%"
            maxWidth="1400px"
            pt="5px"
            image={`url(${headerbg}) bottom / cover no-repeat`}
          >
            <CFView mt="12px">
              <CFImage h="25px" src={mobileHeroText} alt="Order delivery" />
            </CFView>
            <CFView mv="12px" ml="3px" pulsate>
              <OrderPickupButton />
            </CFView>
            {/* <CFView hover mb="5px">
              <OrderDeliveryButton />
            </CFView> */}
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          center
          justifyBetween
          h="100vh"
          maxHeight="700px"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          zIndex={90}
          relative
        >
          <Header />
          <CFView
            row
            center
            ph="3%"
            w="100%"
            maxWidth="1400px"
            pt="5px"
            image={`url(${headerbg}) bottom / cover no-repeat`}
          >
            <CFView mb="1.5px">
              <CFImage h="32px" src={heroText} alt="Order delivery" />
            </CFView>
            <CFView pt="3px" ml="25px">
              <OrderPickupButton />
            </CFView>
            {/* <CFView hover pt="3px" ml="15px">
              <OrderDeliveryButton />
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
