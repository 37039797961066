import React from 'react'
import { Parallax } from 'react-parallax'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import { bigchickentownCoquitlam, bigchickentownSurrey } from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let aboutImageMobile = null
        let aboutImage = null
        if (locationId === 'cZlcTJODkjWeRTnsTpXH') {
          aboutImage = bigchickentownSurrey
          aboutImageMobile = bigchickentownSurrey
        } else if (locationId === 'zxKaE5FHNJGxFFH3nk5d') {
          aboutImage = bigchickentownCoquitlam
          aboutImageMobile = bigchickentownCoquitlam
        }
        return (
          <CFView>
            <MobileScreen>
              <CFView
                column
                center
                mt="10px"
                mb="15px"
                boxShadow="0 2px 2px rgba(0,0,0,.5)"
              >
                <CFImage
                  src={aboutImageMobile}
                  w="100%"
                  alt="aboutImageMobile"
                />
              </CFView>
            </MobileScreen>
            <DefaultScreen>
              <CFView
                row
                justifyStart
                pv="10%"
                pr="40px"
                maxWidth="1400px"
                h="600px"
                image={`url(${aboutImage}) center / cover no-repeat`}
                style={{ backgroundAttachment: 'fixed' }}
              ></CFView>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
