import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  LocationSelect,
  MobileScreen,
} from 'components'
import { headerbg, logo, logoTitle, viewMenu } from 'images'
import { dispatch } from 'store'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/jwtXW2Bc6tUEEYtcnIIt/locations/MvLV4OUJjaddWhfyWwXM'

const images = []

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          w="100%"
          image={`url(${headerbg}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView row center>
            <CFImage h="65px" mv="5px" src={logo} alt="Big Chicken Town Logo" />
            <CFImage
              h="18px"
              ml="10px"
              mv="5px"
              src={logoTitle}
              alt="Big Chicken Town Logo Title"
            />
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="65px"
          bg="transparent"
          zIndex={98}
          image={`url(${headerbg}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          w="100%"
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="30px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="140px"
                mt="5px"
                ml="10px"
                src={logo}
                alt="Big Chicken Town Logo"
                zIndex={98}
              />
              <CFImage
                h="30px"
                mt="20px"
                ml="5px"
                src={logoTitle}
                alt="Big Chicken Town Logo Title"
              />
              <CFSelect selector={dispatch.restaurant.getHasMultipleLocations}>
                <CFView mt="14px" ml="18px" zIndex={99}>
                  <LocationSelect />
                </CFView>
              </CFSelect>
            </CFView>
            {/* <CFView hover mt="12px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="Big Chicken Town View Menu Button"
                />
              </CFLink>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
