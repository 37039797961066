import React from 'react'
import {
  CFImage,
  CFLink,
  CFSelect,
  CFView,
  DefaultScreen,
  MobileScreen,
} from 'components'
import {
  locationMapSurrey,
  locationMapCoquitlam,
  mobileMapSurrey,
  mobileMapCoquitlam,
} from 'images'
import { dispatch } from 'store'

export default () => {
  return (
    <CFSelect selector={dispatch.restaurant.getSelectedLocationId}>
      {locationId => {
        let locationLink = ''
        let mapImageMobile = null
        let mapImage = null
        if (locationId === 'cZlcTJODkjWeRTnsTpXH') {
          locationLink = 'https://g.page/big-chicken-town?share'
          mapImage = locationMapSurrey
          mapImageMobile = mobileMapSurrey
        } else if (locationId === 'zxKaE5FHNJGxFFH3nk5d') {
          locationLink = 'https://g.page/big-chicken-town-coquitlam?share'
          mapImage = locationMapCoquitlam
          mapImageMobile = mobileMapCoquitlam
        }
        return (
          <CFView>
            <MobileScreen>
              <CFLink href={locationLink}>
                <CFImage
                  src={mapImageMobile}
                  w="100%"
                  alt="Big Chicken Town Map"
                />
              </CFLink>
            </MobileScreen>
            <DefaultScreen>
              <CFLink href={locationLink}>
                <CFImage src={mapImage} w="100%" alt="Big Chicken Town Map" />
              </CFLink>
            </DefaultScreen>
          </CFView>
        )
      }}
    </CFSelect>
  )
}
