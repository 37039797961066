import React from 'react'
import { CFImage, CFLink, CFView } from 'components'
import { cflogo, headerbg } from 'images'

export default () => {
  return (
    <CFView
      column
      center
      h="65px"
      image={`url(${headerbg}) bottom / cover no-repeat`}
    >
      <CFView h6 color="white" content>
        COPYRIGHT © 2020 CODEFUSION INC.
      </CFView>
      <CFLink href="https://codefusion.tech">
        <CFImage h="25px" pt="5px" src={cflogo} alt="CodeFusion" />
      </CFLink>
    </CFView>
  )
}
